<div class="contacto-cliente container py-4">
	<div class="row">
		<div class="col col-3">
			<div class="contacto-cliente__circle">{{ dataCtcCliente.nombre ? dataCtcCliente.nombre[0] : '' }}{{ dataCtcCliente.apellido1 ? dataCtcCliente.apellido1[0] : '' }}</div>
		</div>
		<div class="col col-9">
			<h4>{{ dataCtcCliente.label }}</h4>
			<div>
				<div class="row color-costo" *ngIf="dataCtcCliente.cargo">
					<label class="mb-0 mx-3 my-2 d-flex align-items-center"><em class="fa fa-briefcase fa-2x me-2"></em> {{ dataCtcCliente.cargo }}</label>
				</div>

				<div class="row color-costo" *ngIf="dataCtcCliente.correoElectronico">
					<label class="mb-0 mx-3 my-2 d-flex align-items-center"><em class="fa fa-envelope fa-2x me-2"></em> {{ dataCtcCliente.correoElectronico }}</label>
				</div>

				<div class="row color-costo" *ngIf="dataCtcCliente.telefonoMovil || dataCtcCliente.telefonoFijo">
					<label class="mb-0 mx-3 my-2 d-flex align-items-center"
						><em class="fa fa-phone fa-2x me-2"></em> {{ dataCtcCliente.telefonoMovil }}{{ dataCtcCliente.telefonoMovil && dataCtcCliente.telefonoFijo ? ' / ' : '' }}
						{{ dataCtcCliente.telefonoFijo }}</label
					>
				</div>

				<div class="row color-costo" *ngIf="dataCtcCliente.fchNacimiento">
					<label class="mb-0 mx-3 my-2 d-flex align-items-center"><em class="fas fa-birthday-cake fa-2x me-2"></em> {{ dataCtcCliente.fchNacimiento }}</label>
				</div>
			</div>
		</div>
	</div>
</div>
